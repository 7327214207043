(function ($) {
    var control = new ScrollMagic.Controller();
    ($sections = $(".section--list")),
    ($nav_filtre = $(".section--filtre")),
    ($filter_li = $(".nav__filtre")),
    ($filter_link = $filter_li.find(".link--anim")),
    ($filter_subMenu = $filter_li.find(".sub-menu")),
    ($langSelector = $(".lang-selector")),
    ($menuLang = $langSelector.find(".sub-menu-lang"));

    $langSelector.on("click", function () {
        $menuLang.toggleClass("active");

        $menuLang[$menuLang.hasClass("active") ? "fadeIn" : "fadeOut"]();
    });

    /**********************************************************
     *
     *
     *		      ACORDEON COLUMNS
     *
     **********************************************************/

    var acc = document.getElementsByClassName("accordion");

    if (typeof acc !== "undefined") {
        var panels = document.getElementsByClassName("panel");

        if (typeof panels !== "undefined") {
            panels[0].style.maxHeight = panels[0].scrollHeight + "px";

            var i;

            for (i = 0; i < acc.length; i++) {
                acc[i].addEventListener("click", function () {
                    for (var j = 0; j < panels.length; j++) {
                        panels[j].style.maxHeight = null;
                    }

                    this.classList.toggle("active");
                    var panel = this.nextElementSibling;
                    if (panel.style.maxHeight) {
                        panel.style.maxHeight = null;
                    } else {
                        panel.style.maxHeight = panel.scrollHeight + "px";
                    }
                });
            }
        }
    }

    /**********************************************************
     *
     *
     *		      HOMEPAGE COLUMNS
     *
     **********************************************************/

    if ($(".list-products").length) {
        var element_position = $(".list-products").offset().top;
        var footer_position = $("#lesplus").offset().top;
        var footer_height = $("#lesplus").height();
        var footer_margin = "-" + footer_height + "px";

        $.fn.scrollBottom = function () {
            return this.scrollTop() + this.height();
        };

        function change_css_columns() {
            var column_margin = 0;
            var y_scroll_top = $(document).scrollTop();
            var y_scroll_bottom = $(window).scrollBottom();
            var element_bottom =
                y_scroll_top +
                $(".left-column").height() +
                parseInt($(".left-column").css("margin-top")) +
                60;

            if (y_scroll_bottom > footer_position) {
                $(".homepage_column ").removeClass("fixed");
                $(".homepage_column ").addClass("footer-reached");
            } else if (y_scroll_top > element_position) {
                $(".homepage_column ").addClass("fixed");
                $(".homepage_column").css("margin-top", "");
                $(".homepage_column ").removeClass("footer-reached");
            } else {
                $(".homepage_column ").removeClass("fixed");
                $(".homepage_column ").removeClass("footer-reached");
            }
        }

        change_css_columns();
        $(window).on("scroll resize", function () {
            change_css_columns();
        });
    }

    /**********************************************************
     *
     *
     *		      SMOUSE SCROLL
     *
     **********************************************************/
    $filter_link.each(function (index) {
        var $self = $(this);
        $self.on("click", function (e) {
            e.preventDefault();
            var _href = $(this).attr("href");
            TweenMax.to(window, 2, {
                ease: Power3.easeInOut,
                scrollTo: _href
            });
        });
    });

    /**********************************************************
     *
     * 			Animation WORD page d'accueil
     *
     **********************************************************/
    if ($(".anim_word").length > 0) {
        // Animation
        var $words_ctr = $(".anim_word"),
            $words = $words_ctr.find("span"),
            _lenght = $words.length,
            _active = 0,
            intervalID;

        $words.each(function (index) {
            if (index != _active) {
                TweenMax.set($(this), {
                    autoAlpha: 0,
                    top: "-100%"
                });
            } else {
                $(this).addClass("active");
            }
        });
        // Animation
        function flash_word() {
            var _tmp = _active + 1;
            if (_tmp >= _lenght) {
                _tmp = 0;
            }
            TweenMax.fromTo(
                $words.eq(_tmp),
                0.4, {
                    autoAlpha: 0,
                    top: "-50%"
                }, {
                    autoAlpha: 1,
                    top: "0",
                    className: "+=active"
                }
            );
            TweenMax.fromTo(
                $words.eq(_active),
                0.4, {
                    autoAlpha: 1,
                    top: "0%"
                }, {
                    autoAlpha: 0,
                    top: "50%",
                    className: "-=active"
                },
                -0.2
            );
            _active = _tmp;
            clearInterval(intervalID);
        }

        function change_word() {
            flash_word();
            intervalID = setInterval(change_word, 5000);
        }
        change_word();
    }

    /**********************************************************
     *
     *
     *		     Filtre
     *
     **********************************************************/

    var products = $(".list__fiche__produit");

    $(".btn-filter").on("click", function () {
        var value = $(this).attr("data-filter");
        var category = $(this).attr("data-cat");

        if (value == "all") {
            $(".product").fadeIn("800");
        } else {
            products
                .find('div.product[data-cat="' + category + '"]')
                .not('[data-tag~="' + value + '"]')
                .fadeOut();
            products
                .find(
                    'div.product[data-cat="' +
                    category +
                    '"][data-tag~="' +
                    value +
                    '"]'
                )
                .fadeIn();
        }
        $(this)
            .addClass("active")
            .siblings()
            .removeClass("active");
    });

    /**********************************************************
     *
     * 						scroll TOP
     *
     **********************************************************/
    var $scrollTop = $("#scrollTop");

    $scrollTop.find("a").on("click", function (e) {
        e.preventDefault();
        $("html, body").animate({
                scrollTop: 0
            },
            "slow",
            "swing"
        );
    });

    $(window).scroll(function () {
        var topPos = $(this).scrollTop();
        // if user scrolls down - show scroll to top button
        if (topPos > 100) {
            $scrollTop.addClass("active");
        } else {
            $scrollTop.removeClass("active");
        }
    }); // scroll END

    /**********************************************************
     *
     * 			Init modal pour chaque fiche de produit
     *
     **********************************************************/
    var _width = $(".container").width(),
        _pop_active = false;
    $(".fiche__content").each(function (index) {
        var $link = $(this),
            $modal = $(this).data("izimodal-open"),
            _width = 500,
            _scrollMarginTop = 0;

        if ($modal != null) {
            if ($link.hasClass("fiche__produit--modalbg")) {
                _width = 700;
            }
            $($modal).iziModal({
                theme: "",
                width: _width,
                navigateArrows: true,
                overlay: true,
                overlayClose: true,
                transitionIn: "comingIn",
                transitionOut: "comingOut",
                transitionInOverlay: "fadeIn",
                transitionOutOverlay: "fadeOut",
                overlayColor: "rgba(00,00,00,.6)",
                closeButton: true,
                onOpening: function (modal) {
                    $("body").addClass("is-fixed");
                    _scrollMarginTop = $(window).scrollTop();
                    _pop_active = true;
                },
                onClosing: function (modal) {
                    _pop_active = false;
                },
                onClosed: function (modal) {
                    if (_pop_active == false) {
                        $("body").removeClass("is-fixed");
                        //TweenMax.set($('body'),{scrollTop: _scrollMarginTop });
                        //destroy_scroll_pin_nav();
                    }
                }
            });
        }
    });

    /**********************************************************
     *
     * 			Init modal pour chaque fiche de produit
     *
     **********************************************************/

    $(".iziModal").each(function (index) {
        var $modal = $(this),
            $quantite = $modal.find(".list_quantite");

        if ($quantite.length > 0) {
            var $quantite_item = $quantite.find(".list_quantite-item"),
                $prix_ht = $modal.find(".fiche__price-ht > span.price"),
                $prix_ttc = $modal.find(".fiche__price-ttc > span.price"),
                $product_id = $quantite
                .find(".list_quantite-item")
                .attr("data-variationID");
            $add_to_cart = $modal.find(".add_to_cart_button");

            $quantite_item.on("click", function (e) {
                e.preventDefault();

                var $self = $(this),
                    $add_to_cart_button = $modal.find(".add_to_cart_button");
                $add_to_cart = $modal.find(".add_to_cart_inline");
                (_prixHT = $self.attr("data-prixht")),
                (_prixTTC = $self.attr("data-prixttc"));
                _variationID = $self.attr("data-variationID");

                $quantite_item.removeClass("active");
                $self.addClass("active");

                TweenMax.to($prix_ht, 0.2, {
                    text: _prixHT,
                    delay: 0.1,
                    ease: Linear.easeNone
                });
                TweenMax.to($prix_ttc, 0.2, {
                    text: _prixTTC,
                    delay: 0.1,
                    ease: Linear.easeNone
                });

                // $add_to_cart_button.hide();
                $add_to_cart.each(function (index) {
                    if ($(this).attr("data-quantity") == $self.html()) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });
            });
        }
    });

    /**********************************************************
     *
     * 			Init modal pour chaque "COMMENT CA MARCHE"
     *
     **********************************************************/
    var $modal_ccm = $(".iziModal--add");

    $modal_ccm.iziModal({
        theme: "",
        width: _width,
        navigateArrows: true,
        overlay: true,
        overlayClose: true,
        transitionIn: "comingIn",
        transitionOut: "comingOut",
        transitionInOverlay: "fadeIn",
        transitionOutOverlay: "fadeOut",
        overlayColor: "rgba(00,00,00,.6)"
    });

    var $maps = $("#maps_ctr"),
        $maps_hover = $maps.find(".hover"),
        $iframe = $maps.find("iframe"),
        _iframe_src = $iframe.attr("src"),
        _init_iframe = false;

    $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
        e.target; // newly activated tab
        e.relatedTarget; // previous active tab

        if ($(e.target).attr("href") == "#livraison" && _init_iframe == false) {
            TweenMax.set($maps_hover, {
                autoAlpha: 1
            });
            TweenMax.set($iframe, {
                attr: {
                    src: _iframe_src
                }
            });
            TweenMax.to($maps_hover, 0.2, {
                delay: 0.5,
                autoAlpha: 0
            });
            _init_iframe = true;
        }
    });

    /**********************************************************
     *
     * 			Init navigation des filtres position fixe au scoll
     *
     **********************************************************/
    if ($nav_filtre.length > 0) {
        // -> $nav_filtre = $(.section--filtre)
        var _heightFiltre = $nav_filtre.height();
        _heightFiltre = _heightFiltre * -0.5;

        var scene = new ScrollMagic.Scene({
                triggerElement: ".section--filtre",
                offset: _heightFiltre,
                triggerHook: 0
            })
            .setPin(".section--filtre")
            .setClassToggle(".section--filtre", "section--filtre--full")
            .addTo(control);
    }

    /**********************************************************
    *
    * 			Animation sur la navigation secondaire
	*		      section--filtreProgress bar ( sur le texte ) au scroll
    *
    **********************************************************/
    

	function init_scroll_progress_nav(){

        $sections.each(function (index) {
            
			var $self		= $(this),
				_id 		= $self.attr('id'),
				$link		= $filter_li.find('a[href=#'+_id+'] > div'),
				$linkBefore	= $link.find('.before'),
				$navleft    = $self.find('.title__section'),
				_widthL		= $link.width(),
				_topS       = $self.offset().top,
				_hauteurS 	= $self.outerHeight(),
                _tmp = 0;
            
			var scene		= new ScrollMagic.Scene({
                        triggerElement: "#"+_id,
                        duration: _hauteurS,
                        triggerHook: 0,
                        reverse: true,
                })
                .setPin($navleft)
                .addTo(control)
                .on("start" ,function(e){
                    TweenMax.set( $linkBefore ,{width: '0%' });
                })
                .on("progress", function (e) {
                    _tmp = ( ( $(window).scrollTop() - _topS ) * _widthL ) / _hauteurS ;
                    TweenMax.set( $linkBefore ,{width: _tmp });
                })
                .on("end" ,function(e){
                    TweenMax.set( $linkBefore ,{width: '100%' });
            });
	      	this.destroy_scene = scene;
		});
	}
	
	function destroy_scroll_magic(){
		$sections.each(function(index){
			this.destroy_scene.destroy(true);
		});
       init_scroll_progress_nav();
	}

	init_scroll_progress_nav();

    $(".ico-recyclable").hover(function (e) {
        $(this)
            .next("span")
            .toggleClass("active", e.type === "mouseenter");
    });

    $(".indic-veggie").hover(function () {
        $(this)
            .parents(".fiche__price")
            .find(".note-veggie")
            .toggleClass("active");
    });
    $(".indic-hallal").hover(function () {
        $(this)
            .parents(".fiche__price")
            .find(".note-hallal")
            .toggleClass("active");
    });
    $(".indic-kacher").hover(function () {
        $(this)
            .parents(".fiche__price")
            .find(".note-kacher")
            .toggleClass("active");
    });
})(jQuery);