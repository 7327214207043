(function($) {

	var $header = $('.header'),
		$btn	= $header.find('#menu__btn')
		$nav 	= $header.find('.navbar-collapse')
		;


	$btn.on('click', function(e){
		e.preventDefault();
		$(this).toggleClass('is-active');
		$nav.toggleClass('is-active');
		$header.toggleClass('is-active');
	});

})(jQuery);

